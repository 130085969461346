export const downloadFile = (url: string | string[]) => {
  const urls = Array.isArray(url) ? url : [url]

  urls.forEach((url) => {
    const aEl = document.createElement('a')
    aEl.setAttribute('href', url)
    aEl.setAttribute('download', 'download')
    aEl.setAttribute('target', '_blank')
    // aEl.setAttribute('download', `file_${Math.floor(Math.random() * 10000)}.mp3`)

    document.body.appendChild(aEl)
    aEl.click()
    document.body.removeChild(aEl)
  })
}
