import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { AppFeatures } from '@ti-platform/contracts'
import { PricingPlanService } from '../services/pricing-plan.service'

export const requirePricingPlan = (requiredFeatures: AppFeatures[]): CanActivateFn => {
  return async () => {
    const router = inject(Router)
    const pricingPlanService = inject(PricingPlanService)
    const isAllowed = await pricingPlanService.areFeaturesAllowed(requiredFeatures)
    if (!isAllowed) {
      setTimeout(() => router.navigate(['access-denied'], { skipLocationChange: true }))
    }
    return true
  }
}
