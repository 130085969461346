/**
 * Returns the closest item in array with the least difference from given numeric value
 */
export const findClosestBy = <T = any>(items: T[], byKey: keyof T, target: number): T | null => {
  let left = 0
  let right = items.length - 1

  while (left <= right) {
    const mid = Math.floor((left + right) / 2)

    if (items[mid][byKey] === target) {
      return items[mid] // Exact match
    } else if ((items[mid][byKey] as number) < target) {
      left = mid + 1
    } else {
      right = mid - 1
    }
  }

  // Determine closest of `right` and `left`
  const leftItem = items[left] || null
  const rightItem = items[right] || null

  if (!leftItem) return rightItem // If left index is out of bounds
  if (!rightItem) return leftItem // If right index is out of bounds

  // Return the closest timestamp
  return Math.abs((leftItem[byKey] as number) - target) <
    Math.abs((rightItem[byKey] as number) - target)
    ? leftItem
    : rightItem
}
